import React from "react";
import "twin.macro";
import { graphql } from "gatsby";
import { PageLayout, CTA, Exemptions } from "../components";

const RetinalScreening = ({ data: { strapiDsrRetinalScreening } }) => {
  return (
    <PageLayout {...strapiDsrRetinalScreening} flourish={1}>
      <Exemptions />
      <CTA />
    </PageLayout>
  );
};

export default RetinalScreening;

export const query = graphql`
  query retinalScreeningPageQuery {
    strapiDsrRetinalScreening {
      content
      subtitle
      intro
      title
    }
  }
`;
